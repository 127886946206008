<template>
  <Dropdown
    dropdown-class="mt-0 left-0 mobile-only:fixed mobile-only:top-60 mobile-only:w-screen mobile-only:bottom-0 z-[500] mobile-only:overflow-auto mobile-only:mt-0 mobile-only:border-t mobile-only:border-t-gray-300"
    transition-name="t-facet"
    @close="buttonVisible = false"
  >
    <template #toggle="{ onClick, visible }">
      <button
        class="flex items-center p-15 md:p-20 font-medium text-sm md:text-base text-gray-900 gap-10 hover:text-green-alt hover:bg-green/5 select-none whitespace-nowrap"
        :class="{ 'pointer-events-none opacity-25': disabled }"
        :disabled="disabled"
        @click="onClick"
      >
        <span>{{ label }}</span>
        <SpriteSymbol
          name="chevron"
          class="w-[13px] h-[8px] mt-2"
          :class="{ 'rotate-180': visible }"
        />
      </button>
    </template>

    <template v-if="!disabled" #default="{ close }">
      <div
        class="w-full md:w-[400px] md:max-h-[410px] md:overflow-auto h-full flex flex-col"
      >
        <p
          class="font-semibold text-lg p-20 flex justify-between items-center sticky top-0 bg-white z-50"
        >
          <span>{{ label }}</span>
          <button class="p-10 -mr-10" @click="close">
            <SpriteSymbol name="close" class="w-25 h-25 text-green-alt" />
          </button>
        </p>
        <div class="flex-1 pb-15">
          <NavigatorFacetTerm
            v-for="term in terms"
            :key="term.value"
            v-bind="term"
            :facet-id="id"
            @click="showButton"
          />

          <slot :show-button="showButton"></slot>
        </div>

        <div
          v-show="buttonVisible"
          class="p-20 bg-white sticky bottom-0 z-50 border-t border-t-gray-300 mobile-only:!block mt-auto"
        >
          <button class="button is-primary w-full is-narrow" @click="close">
            {{ $texts('navigator.showResults', 'Resultate anzeigen') }}
          </button>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script lang="ts" setup>
import {
  SearchFacetTerm,
  ValidFacetKey,
} from '~/sites/pharmago/helpers/pharmacies-api/types'

const props = defineProps<{
  id: ValidFacetKey
  label: string
  terms: SearchFacetTerm[]
}>()

const buttonVisible = ref(false)

const showButton = () => (buttonVisible.value = true)

const disabled = computed(() => !props.terms.length)
</script>

<style lang="postcss">
.t-facet-enter-active,
.t-facet-leave-active {
  @apply mobile-only:transition mobile-only:duration-500;
}
.t-facet-enter-from,
.t-facet-leave-to {
  @apply mobile-only:transform mobile-only:translate-y-full;
}
</style>
